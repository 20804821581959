<template>
    <div class="testPage" ref="testPage">
        <div class="task_list"
             :style="{minHeight:`${listH}px`,maxHeight:`${listH}px`}">
            <van-pull-refresh
                    success-text="刷新成功"
                    v-model="refreshing"
                    @refresh="onRefresh">
                <van-list
                    style="height: 100%;"
                    v-model="isLoading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad()"
                >
                    <div class="list_item" @click="cardItemClick(item)" v-for="(item,index) in examList" :key="index">
                        <div class="item_top">
                            <div class="task_info">
                                <div class="task_title">{{item.examinationName}}</div>
                                <div class="task_info_box">
                                    <div class="info_item">满分：{{item.fullMarkScore}}</div>
                                    <van-tag plain class="mr5" type="primary" style="margin-right: 20px">得分: {{ item.studentTotalScore }}分</van-tag>
                                    <van-tag plain>等级: {{ item.rank }}</van-tag>
                                    <!--<div class="info_item result" v-if="item.correctable === 'corrected'">-->
                                    <!--    得分：{{item.studentTotalScore}}-->
                                    <!--</div>-->
                                    <div class="info_item "></div>
                                    <van-tag plain class="analyses">查看分析</van-tag>
                                    <!--                                <div class="info_item undo" v-if="item.status === -1">作业过期</div>-->
                                    <!--                                <div class="info_item start" v-if="item.status === 0">开始做题</div>-->
                                    <!--                                <div class="info_item process" v-if="item.status === 1">继续做题</div>-->
                                    <!--                                <div class="info_item done" v-if="item.status === 2">已交作业</div>-->
                                    <!--                                <div class="info_item analysis" v-if="item.status === 3">查看分析</div>-->
                                </div>
                            </div>
                            <div class="item_arrow">
                                <img src="../../../public/iconImg/icon_arrow.png" alt="">
                            </div>
                        </div>
                        <div class="item_bottom">
                            <div class="item_grade">{{$Z.getGradeTitleById(item.gradeid,gradeInfo)}}</div>
                            <div class="item_date">{{item.openTime}}</div>
                            <div class="item_btn">
                                <div class="card_btn undo" v-if="item.status === -1">作业过期</div>
                                <div class="card_btn submit" v-if="item.status === 0">开始做题</div>
                                <div class="card_btn process" v-else-if="item.status === 1">继续做题</div>
                                <div class="card_btn detail" v-else-if="item.status === 2">查看详情</div>
                                <div class="card_btn analysis" v-else-if="item.status === 3">查看分析</div>
                            </div>
                        </div>
                    </div>
                </van-list>
<!--                <van-empty v-if="examList && examList.length === 0" description="暂无数据" />-->
            </van-pull-refresh>
        </div>


    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import TaskApi from "../../api/TaskApi";
    export default {
        name: "Test",
        data() {
            return {
                /*配置项*/
                //页面高度
                pageH: 0,
                //吸顶顶部偏移高度
                topH: 0,
                //列表高度，用于计算吸顶距离
                listH: 0,
                //是否吸顶
                isFixed: false,
                refreshing : false,
                finished : false,
                //是否下拉刷新
                isLoading: false,
                //测试列表
                examList: [],
                //页码
                pageIndex: 0,
                //每页条数
                pageSize: 10,
                total : 0,

                //底部加载距离,
                scrollBottom: 50,
                //下拉加载防抖对象
                debounceObj: null,
                //吸顶防抖
                stickyDebounce: null,
            }
        },
        mounted() {
            this.debounceObj = this.$A.debounce(this.debounceHandle, 1000);
            // this.stickyDebounce = this.$A.debounce(this.fixedHandle, 10);
        },
        activated() {
            this.pageInit();
            // window.addEventListener('resize', this.pageInit);
            this.$nextTick(() => {
                setTimeout(() => {
                    document.querySelector('.van-pull-refresh').addEventListener('scroll', this.handleScroll, true)//监听函数
                }, 100)

            })

        },
        deactivated() {
            // window.removeEventListener('resize', this.pageInit);
        },
        destroyed() {
        },
        computed: {
            ...mapGetters({
                //科目列表
                subjectInfo: 'common/getSubjectList',
                //年级列表
                gradeInfo: 'common/getGradeList',
            }),
            listResult() {
                let res = [];
                return res;
            }
        },

        watch: {
        },
        methods: {
            //卡片点击
            cardItemClick(obj) {
                this.$router.push({
                    name: 'studentExamination',
                    query: {examinationId: obj.examinationid, schoolId: obj.schoolId, studentId: obj.studentid}
                })
            },

            //刷新
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.pageIndex = 0
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.isLoading = true;
                this.examList = [];
                this.onLoad();
                // this.getExamList('', true);
            },
            onLoad(){
                this.getExamList();
            },

            //获取题目列表
            getExamList() {
                let params = {
                    examType: 'unified',
                    start: this.pageIndex * this.pageSize,
                    length: this.pageSize,
                    completionStatus: ''
                }
                //默认获取全部，筛选结果直接本地过滤
                TaskApi.userExamIsStudent(params).then(res => {
                    if (res.success) {
                        res.data.forEach(item => {
                            this.examList.push(item);
                        })

                        this.total = res.recordsTotal;
                        this.isLoading = false;
                        this.refreshing = false;
                        this.pageIndex++;
                        if (this.pageIndex * this.pageSize >= res.recordsTotal){
                            this.finished = true
                        }

                    }
                })
            },
            //页面初始化
            pageInit() {

                // this.onRefresh()
                // this.isLoading = false;
                // this.getExamList('pageInit');
                setTimeout(() => {
                    this.$nextTick(() => {
                        let page = this.$refs.testPage.getBoundingClientRect();
                        // let swipe = this.$refs.swipeBox.getBoundingClientRect();

                        this.pageH = this.listH = page.height;
                        // this.listH = this.pageH - this.topH - task.height;
                    })
                }, 300)

            },
            handleScroll() {
                let target = document.querySelector('.van-pull-refresh');
                // 变量scrollTop是滚动条滚动时，距离顶部的距离
                let scrollTop = target.scrollTop;
                //变量windowHeight是可视区的高度
                let windowHeight = target.clientHeight;
                //变量scrollHeight是滚动条的总高度
                let scrollHeight = target.scrollHeight;

                let bottom = scrollHeight - windowHeight - scrollTop;
                if (bottom < this.scrollBottom) {
                    if (this.debounceObj) this.debounceObj();
                }

            },
        }

    }
</script>

<style lang="scss">
    .testPage {
        /*::v-deep .van-sticky{*/
        /*    position: fixed;*/
        /*}*/
        .grade_select {
            background: #fff;
            height: 44px;
            /*position: relative;*/
            @extend .flex_row_center;
            justify-content: flex-start;
            padding: 0 12px;
        }


        .analyses {
            color: #019FE8;
        }

        .subject_select, .task_type {
            .van-tab {
                font-size: 14px;
                line-height: 20px;
                color: rgba(153, 153, 153, 1);
            }

            .van-tabs__nav--complete {
                padding-left: 0;
                padding-right: 0;
            }

            .van-tabs__line {
                background-color: rgba(1, 159, 232, 1);
                height: 3px;
                width: 20px;
            }

            .van-tab--active {
                color: rgba(1, 159, 232, 1);
            }
        }

        .swipe_box {
            padding: 16px;


            .swipe_content {
                border-radius: 5px;

                .van-swipe-item {

                    color: #fff;
                    font-size: 20px;
                    line-height: 150px;
                    text-align: center;
                    background-color: #39a9ed;

                    &:nth-child(1) {
                        background-color: rgba(255, 211, 211, 1);
                    }

                    &:nth-child(2) {
                        background-color: rgba(255, 201, 105, 1);
                    }

                    &:nth-child(3) {
                        background-color: rgba(71, 226, 151, 1);
                    }
                }
            }
        }

        .task_list {
            padding-bottom: 12px;
            overflow: auto;

            .list_item {
                padding: 20px 20px 16px;
                box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                margin: 16px;

                .item_top {
                    @extend .flex_row_center;
                    padding-bottom: 16px;
                    border-bottom: 1px solid rgba(238, 238, 238, 1);

                    .subject_icon {
                        @extend .flex_row_center;
                        width: 40px;
                        height: 40px;
                        border-radius: 5px;
                        background: #00A0E8;
                        font-size: 16px;
                        font-weight: bold;
                        color: #fff;
                        margin-right: 12px;

                    }

                    .task_info {
                        flex: 1;
                        margin-right: 12px;
                        @extend .flex_column;
                        align-items: flex-start;

                        .task_title {
                            font-size: 16px;
                            font-weight: bold;
                            color: $defaultColor;
                            width: 100%;
                            text-align: left;
                        }

                        .task_info_box {
                            @extend .flex_row_center;
                            width: 100%;
                            justify-content: flex-start;
                            font-size: 12px;

                            padding: 10px 0;
                            .info_item {
                                color: rgba(102, 102, 102, 1);
                                margin-right: 12px;
                                @extend .flex_row_center;
                                justify-content: flex-start;

                                &:last-child {
                                    justify-content: flex-end;
                                }

                                &.result {
                                    color: rgba(255, 85, 101, 1);
                                }

                                &.process {
                                    color: #FFA201;
                                }

                                &.start {
                                    color: #019fe8;
                                }

                                &.undo {
                                    color: rgba(102, 102, 102, 1);
                                }

                                &.self {
                                    color: #FFA201;
                                }

                                &.undo {
                                    color: #333;
                                }
                            }

                        }
                    }

                    .item_arrow {
                        @extend .flex_row_center;

                        img {
                            width: 20px;
                            height: 20px;
                            transform: rotate(-180deg);
                        }
                    }
                }

                .item_bottom {
                    @extend .flex_row_center;
                    font-size: 12px;
                    color: rgba(102, 102, 102, 1);
                    padding-top: 16px;

                    .item_grade {
                        min-width: 80px;
                        text-align: left;
                    }

                    .item_date {

                    }

                    .item_btn {
                        flex: 1;
                        @extend .flex_row_center;
                        justify-content: flex-end;

                        .card_btn {
                            @extend .flex_row_center;
                            font-size: 14px;
                            color: #fff;
                            height: 30px;
                            width: 80px;
                            transition: all .3s ease;
                            border-radius: 15px;


                            &.undo {
                                background: transparent;
                                color: #666;
                            }

                            &.detail {

                                background: linear-gradient(-21deg, #019FE8 0%, #6AD0FF 100%);

                                &:active {
                                    background: linear-gradient(-21deg, #019FE8 100%, #6AD0FF 0%);
                                }
                            }

                            &.submit {
                                background: linear-gradient(-21deg, rgba(255, 152, 144, 1) 100%, rgba(255, 101, 92, 1) 0%);

                                &:active {
                                    background: linear-gradient(-21deg, rgba(255, 152, 144, 1) 0%, rgba(255, 101, 92, 1) 100%);
                                }
                            }

                            &.process {

                                background: linear-gradient(-21deg, rgba(255, 163, 0, 1) 0%, rgba(255, 201, 105, 1) 100%);

                                &:active {
                                    background: linear-gradient(-21deg, rgba(255, 163, 0, 1) 100%, rgba(255, 201, 105, 1) 0%);
                                }
                            }

                            &.analysis {
                                background: linear-gradient(-21deg, rgba(71, 226, 151, 1) 100%, rgba(1, 194, 101, 1) 0%);

                                &:active {
                                    background: linear-gradient(-21deg, rgba(71, 226, 151, 1) 0%, rgba(1, 194, 101, 1) 100%);
                                }
                            }


                        }
                    }
                }
            }
        }

        /*ipad*/
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
            .grade_select {
                height: 88px;
                padding: 0 24px;


            }
            .grade_text {
                font-size: 32px;

                &.inline {
                    height: 88px;
                    line-height: 88px;
                }

                &:after {
                    border-width: 10px;
                    right: -32px;
                }
            }
            .grade_list {
                /*top: 88px;*/
                padding: 0 24px;
                width: calc(100% - 48px);

                .list_title {
                    font-size: 32px;
                }

                .grade_group {
                    margin: 24px 0;

                    .group_list {
                        .grade_item {
                            height: 80px;
                            line-height: 80px;
                            width: 180px;
                            border-radius: 10px;
                            margin-bottom: 32px;
                        }
                    }
                }
            }

            .subject_select, .task_type {
                .van-tab {
                    font-size: 28px;
                    line-height: 40px;
                }

                .van-tabs__line {
                    height: 6px;
                    width: 40px;
                }

                .van-tabs__wrap--scrollable .van-tab {
                    padding: 0 24px;
                }
            }

            .swipe_box {
                padding: 32px;

                .swipe_content {
                    border-radius: 10px;

                    .van-swipe-item {
                        font-size: 40px;
                        line-height: 300px;
                    }
                }
            }

            .task_list {
                padding-bottom: 24px;

                .list_item {
                    padding: 40px 40px 32px;
                    box-shadow: 2px 4px 20px 0 rgba(0, 0, 0, 0.2);
                    border-radius: 10px;
                    margin: 32px;

                    .item_top {
                        padding-bottom: 32px;
                        border-bottom: 2px solid rgba(238, 238, 238, 1);

                        .subject_icon {
                            width: 80px;
                            height: 80px;
                            border-radius: 10px;
                            font-size: 32px;
                            margin-right: 24px;

                        }

                        .task_info {
                            margin-right: 24px;

                            .task_title {
                                font-size: 32px;
                            }

                            .task_info_box {
                                font-size: 24px;

                                .info_item {
                                    margin-right: 24px;
                                }

                            }
                        }

                        .item_arrow {
                            img {
                                width: 40px;
                                height: 40px;
                            }
                        }
                    }

                    .item_bottom {
                        font-size: 24px;
                        padding-top: 32px;

                        .item_grade {
                            min-width: 160px;
                        }

                        .item_date {

                        }

                        .item_btn {
                            .card_btn {
                                font-size: 28px;
                                height: 60px;
                                width: 160px;
                                border-radius: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

</style>
